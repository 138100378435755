.container {
    position: relative;
}

.select {
    background: none;
    border: 1px solid var(--border-primary);
    border-radius: var(--border-radius-lg);
    outline: none;
    padding: var(--spacing-size-two) var(--spacing-size-one) var(--spacing-size-two) var(--spacing-size-two);
    color: var(--text-primary);
    -moz-appearance: none;
    -webkit-appearance: none;
}

.select::placeholder {
    color: var(--text-secondary);
}

.select::-ms-expand {
    display: none;
}

.vector {
    position: absolute;
    right: var(--spacing-size-three);
    top: 12px;
    pointer-events: none;
}

.flip {
    top: 11px;
    transform: rotate(180deg);
}
