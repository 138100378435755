.root {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.imageLink {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 80px;
}

.variant {
    display: grid;
    gap: var(--spacing-size-one);
}

.variantRow {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.variantWrapper {
    display: flex;
    gap: var(--spacing-size-one);
    justify-content: space-between;
}

.details {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding-left: var(--spacing-size-three);
    width: 100%;
}

.price {
    display: flex;
    gap: var(--spacing-size-one);
    justify-content: end;
}

.comparePrice {
    display: flex;
    gap: var(--spacing-size-one);
    text-decoration: line-through;
    color: var(--color-brand-titanium);
    justify-content: end;
}

.size {
    display: flex;
    align-items: center;
    color: var(--color-brand-titanium);
}

.sizeSelect {
    margin-left: var(--spacing-size-one);
    color: var(--color-brand-titanium);
}

.colour {
    color: var(--color-brand-titanium);
}

.meta {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-size-two);
    padding: var(--spacing-size-two) 0;
    align-items: center;
}

.controlButton {
    padding: var(--spacing-size-two) var(--spacing-size-two)
        var(--spacing-size-two) 0;
}

.wishlist {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-size-two);
}

@media screen and (max-width: 991px) {
    .root {
        width: 100%;
    }

    .details {
        padding-left: var(--spacing-size-two);
    }
}
