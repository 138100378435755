.root {
    padding: var(--spacing-size-six) var(--spacing-size-nine);
    text-align: center;
}

.header {
    text-align: center;
    justify-content: center;
    margin-bottom: var(--spacing-size-nine);
}
