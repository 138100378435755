.root {
    font-family: 'TWK Everett', serif;
    font-weight: var(--font-weight-4);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    justify-content: center;
    padding: var(--spacing-size-two) var(--spacing-size-three);
}

.buttonLink {
    text-decoration: none;
}

.default {
    background-color: transparent;
    border: 1px solid var(--border-primary);
}

.selected {
    color: var(--text-invert);
    background-color: var(--color-brand-buy);
    border: 1px solid var(--color-brand-buy);
}

.alternative {
    color: var(--text-invert);
    background-color: var(--surface-brand);
    border: 1px solid var(--border-primary);
}

.soldOut {
    color: var(--text-inactive);
    border: 1px solid var(--color-brand-steel);
    background-color: transparent;
}

.added {
    background: var(--color-brand-pay);
    border: 1px solid var(--color-brand-pay);
}

.disabled {
    cursor: not-allowed !important;
}

/* Upsell Cart Button Variant Styling */
.upsellRoot {
    font-family: 'TWK Everett Mono', serif;
    font-weight: var(--font-weight-3);
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: var(--spacing-size-two) 0;
    white-space: nowrap;
    min-width: var(--spacing-size-four);
    justify-content: center;
    text-decoration: underline;
    text-underline-position: under;
    color: var(--text-primary);
}

.upsellRoot:hover {
    color: var(--text-secondary);
}

.upsellRoot.default {
    border: none;
}

.upsellRoot.selected {
    border: none;
    background-color: transparent;
}

.upsellRoot.alternative {
    border: none;
    background-color: transparent;
}

.upsellRoot.soldOut {
    color: var(--text-inactive);
    border: none;
}

.upsellRoot.added {
    border: none;
    background-color: transparent;
}

@media screen and (max-width: 767px) {
    .upsellRoot {
        font-size: var(--font-size-component2);
        line-height: var(--line-height-component2);
    }
}
