.root {
    font-family: 'TWK Everett', serif;
    font-weight: var(--font-weight-4);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: var(--spacing-size-two) var(--spacing-size-three);
    white-space: nowrap;
}

.rootNortheast {
    font-family: 'TWK Everett', serif;
    font-weight: var(--font-weight-4);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    letter-spacing: 0.25px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    padding: var(--spacing-size-two) var(--spacing-size-three);
}

.buttonLink {
    text-decoration: none;
}

.base {
    background-color: var(--color-brand-white);
}

.base:hover {
    color: var(--color-brand-titanium);
    background-color: var(--color-brand-white);
}

.active {
    color: var(--color-brand-white);
    background-color: var(--color-brand-titanium);
}

.ghost {
    border: 1px solid var(--color-brand-black);
}

.ghost:hover {
    border: 1px solid var(--color-brand-aluminium);
    color: var(--color-brand-titanium);
}

.secondary {
    background-color: var(--color-brand-black);
    color: var(--color-brand-white);
}

.secondary:hover {
    background-color: var(--color-brand-titanium);
}

.alt {
    background-color: var(--color-brand-cement);
}

.alt:hover {
    background-color: var(--color-brand-aluminium);
}

.image {
    margin-left: var(--spacing-size-two);
}

.disabled {
    font-family: 'TWK Everett', serif;
    font-weight: var(--font-weight-4);
    font-size: var(--font-size-component1);
    line-height: var(--line-height-component1);
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    cursor: no-drop;
    padding: var(--spacing-size-two) var(--spacing-size-three);
}

.disabledGhost {
    color: var(--color-brand-steel);
    border: 1px solid var(--color-brand-steel);
}

.disabledSecondary {
    background-color: var(--color-brand-titanium);
}

.disabledAlt {
    background-color: var(--color-brand-cement);
    color: var(--color-brand-steel);
}

.inactiveGhost {
    color: var(--color-brand-steel);
    border: 1px solid var(--color-brand-steel);
}

.icon {
    margin-left: var(--spacing-size-two);
}

.radiusSmall {
    border-radius: var(--border-radius-xxs);
}

.radiusMedium {
    border-radius: var(--border-radius-sm);
}

.radiusLarge {
    border-radius: var(--border-radius-lg);
}
