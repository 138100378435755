/* Toggle style */
.toggleGenderButton {
    display: flex;
    background-color: var(--surface-hover-invert);
    border: 2px solid var(--surface-hover-invert);
    border-radius: var(--border-radius-lg);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.toggleButton {
    padding-left: var(--spacing-size-four);
}
