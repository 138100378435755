.root {
    padding-block: var(--fluid-32-72);
    padding-inline: var(--fluid-24-72);
}

.narrow {
    padding-bottom: var(--spacing-size-five)
}

.border {
    border-top: 1px solid var(--color-brand-aluminium);
    border-bottom: 1px solid var(--color-brand-aluminium);
}

.stack > * + * {
    margin-block-start: var(--flow-space, var(--fluid-24-32));
}

.header {
    display: flex;
    align-items: center;
    gap: var(--spacing-size-four);
    flex-wrap: wrap;
}

.subCount {
    color: var(--color-brand-titanium);
}

.description {
    width: max(min(100%, 56.75rem), 66%);
}

@media screen and (max-width: 767px) {
    .description[class*='mmds-subtitle-one'] {
        font-size: var(--font-size-subtitle2);
        line-height: var(--line-height-subtitle2);
    }
}
