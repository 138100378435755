.input {
    border: 1px solid var(--surface-brand);
    margin-top: var(--spacing-size-two);
    padding: var(--spacing-size-two);
    background: none;
    width: 100%;
}

.input::placeholder {
    color: var(--text-secondary);
}
